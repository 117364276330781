import axios from 'axios';
import dl from "../utils/device-language";
import qs from 'qs';

export const ENDPOINT = "https://api-ristoratori.mycia.it/statistics/v1/";
export const NEW_ANALYTICS = "https://logger.mycia.it/v2/logs/webapp";

var data = {
    "action": "",
    "attributes": [process.env.REACT_APP_NAMESPACE],
    "domain": window.location.origin,
    "lang": dl.getDeviceLanguage(),
    "metadata": {},
    "referrer": window.document.referrer,
    "start": Date.now(),
    "url": window.location.href,
    "user_agent": navigator.userAgent,
};

async function trackData(id, action, metadata) {
    data.action = action;
    data.metadata = metadata ?? Object.assign({}, metadata);

    if (
        "production" === process.env.NODE_ENV &&
        "test" !== process.env.REACT_APP_NAMESPACE
    ) {
        try {
            let formdata = new FormData();
            formdata.append("ris_id", id);
            formdata.append("text", JSON.stringify(data));

            return await axios.post(ENDPOINT, formdata);
        } catch (err) {
            console.log(err);
        }
    } else {
        console.log(data);
    }

    return data;
}

async function trackDataV2(id, token, action, metadata, hasOrders) {
    var dataV2 = {
        "data": {
            "type": "webapplog",
            "attributes": {
                "ris_id": id,
                "token": token,
                "action": action,
                "payload": {
                    "action": action,
                    "attributes": [process.env.REACT_APP_NAMESPACE, hasOrders ? "o&p" : "mcm"],
                    "domain": window.location.origin,
                    "lang": dl.getDeviceLanguage(),
                    "metadata": metadata ?? Object.assign({}, metadata),
                    "referrer": window.document.referrer,
                    "start": Date.now(),
                    "url": window.location.href,
                    "user_agent": navigator.userAgent
                }
            }
        }
    }

    if (
        "production" === process.env.NODE_ENV &&
        "test" !== process.env.REACT_APP_NAMESPACE
    ) {
        try {
            // dataV2.data.attributes.payload = JSON.stringify(dataV2.data.attributes.payload);

            return await axios.post(NEW_ANALYTICS, qs.stringify(dataV2));
        } catch (err) {
            console.log(err);
        }
    } else {
        console.log({ dataV2 });
    }

    return dataV2;
}

export default trackData;
export { trackDataV2 };
